<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>
    <b-sidebar
      type="is-light"
      :fullheight="isFullheight"
      :overlay="isOverlay"
      :right="isRight"
      :open.sync="open"
      :on-cancel="closeSideBar"
      :can-cancel="['escape', 'x']"
      style="width: 400px"
      v-if="question"
    >
      <div class="p-0 form">
        <div class="columns is-multiline">
          <div class="column is-10">
            <h1 class="form-title">
              Detail Question
              <span @click="closeSideBar(questionId)">
                <b-icon
                  v-if="!question.deletedAt"
                  size="is-small"
                  icon="pen"
                  class="edit-pen"
                ></b-icon>
              </span>
            </h1>
          </div>
          <div class="column is-2">
            <b-button
              class="button-close-top"
              style="float:right"
              @click="closeSideBar()"
            >
              Close
            </b-button>
          </div>

          <div class="column is-3 question-description">
            Question Id
          </div>
          <div class="column is-9 question-value">
            <b-field>
              {{ question.id }}
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Question Type
          </div>
          <div class="column is-9 question-value">
            <b-field>
              {{ question.questionType }}
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Level
          </div>
          <div class="column is-9 question-value">
            <b-field>
              {{ question.newLevel.name }}
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Category
          </div>
          <div class="column is-9 question-value">
            <b-field>
              {{ question.newCategory.name }}
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Tag
          </div>
          <div class="column is-9 question-value">
            <b-field v-for="tag in question.newTags" :key="tag.id">
              {{ tag.name }}
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Question
          </div>
          <div class="column is-9 question-text question-value">
            <b-field>
              <div v-html="question.question"></div>
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Question Image
          </div>
          <div class="column is-9 question-text question-value">
            <span v-if="images.length > 0">
              <!-- For image -->
              <div
                class="columns is-multiline"
                v-for="(image, indexFile) in images"
                :key="indexFile"
              >
                <div class="column is-12">
                  <strong>Image {{ indexFile + 1 }}</strong>
                </div>

                <img
                  class="column is-12"
                  style="max-height: 300px; max-width: 300px;"
                  :src="getUrlFile(image.filePath)"
                  alt="Question Image"
                />
              </div>
            </span>
            <span v-else>
              No
            </span>
          </div>

          <div class="column is-3 question-description">
            Question Video
          </div>
          <div class="column is-9 question-text question-value">
            <span v-if="videos.length > 0">
              <!-- For Video -->
              <div
                class="columns is-multiline"
                v-for="(video, indexFile) in videos"
                :key="indexFile"
              >
                <div class="column is-12">
                  <strong>Video {{ indexFile + 1 }}</strong>
                </div>
                <video-player
                  class="column is-12"
                  width="320"
                  height="240"
                  autoplay
                  controls="false"
                  muted="false"
                  :options="setOptions(getUrlFile(video.filePath))"
                  :ref="'video_' + indexFile"
                ></video-player>
                <b-button
                  class="ml-3"
                  type="is-hcc"
                  @click="playVideo(indexFile)"
                >
                  Play
                </b-button>
              </div>
            </span>
            <span v-else>
              No
            </span>
          </div>

          <div class="column is-3 question-description">
            Used
          </div>
          <div class="column is-9 question-value">
            <b-field>
              {{ question.counter }}
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Required
          </div>
          <div class="column is-9 question-value">
            <b-field v-if="question.isRequired == 0">
              No
            </b-field>
            <b-field v-if="question.isRequired == 1">
              Yes
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Random Answer
          </div>
          <div class="column is-9 question-value">
            <b-field v-if="question.isRandomAnswer == 0">
              No
            </b-field>
            <b-field v-if="question.isRandomAnswer == 1">
              Yes
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Status
          </div>
          <div class="column is-9 question-value">
            <b-field v-if="question.deletedAt == null && question.name != null">
              Active
            </b-field>
            <b-field v-if="question.deletedAt != null && question.name != null">
              Inactive
            </b-field>
          </div>

          <div class="column is-3 question-description">
            Options
          </div>
          <div class="column is-9 question-value">
            <!-- ranking -->
            <div
              v-if="
                answer.length > 0 &&
                  question.name != null &&
                  question.questionType == 'ranking'
              "
            >
              <b-field
                class="answer-value"
                style="font-weight:bolder"
                v-if="question.leftSideNumber != null"
              >
                Minimum {{ question.leftSideNumber }}
              </b-field>
              <b-field class="answer-value" style="font-weight:bolder" v-else>
                Minimum : No
              </b-field>
              <b-field
                class="answer-value"
                style="font-weight:bolder"
                v-if="question.rightSideNumber != null"
              >
                Maximum : {{ question.rightSideNumber }}
              </b-field>
              <b-field class="answer-value" style="font-weight:bolder" v-else>
                Maximum : No
              </b-field>
              <span v-for="data in answer" :key="data.id">
                <b-field class="answer-value" style="font-weight:bolder">
                  {{ data.description }}
                </b-field>
                <b-field class="answer-value">
                  Answer Id : {{ data.id }}
                </b-field>
                <b-field class="answer-value">Point : {{ data.point }}</b-field>
                <div class="answer-value">
                  <span class="mr-2">Tags :</span>
                  <span v-for="tag in data.newTags" :key="tag.id">
                    <span class="tag-name">
                      {{ tag.name }}
                    </span>
                  </span>
                </div>
              </span>
            </div>

            <!-- essay -->
            <div
              v-else-if="
                answer.length > 0 &&
                  question.name != null &&
                  question.questionType == 'essay' &&
                  question.hasAnswer == 1
              "
            >
              <span v-for="data in answer" :key="data.id">
                <b-field style="font-weight:bolder" class="answer-value">
                  {{ data.description }}
                </b-field>
                <b-field class="answer-value">
                  Answer Id : {{ data.id }}
                </b-field>
                <div class="answer-value">
                  <span class="mr-2">Tags :</span>
                  <span v-for="tag in data.newTags" :key="tag.id">
                    <span class="tag-name">
                      {{ tag.name }}
                    </span>
                  </span>
                </div>
              </span>
            </div>

            <!-- linear scale -->
            <div
              v-else-if="
                question.questionType == 'linear scale' && question.name != null
              "
            >
              <b-field class="answer-value">
                <strong>From :</strong>
                {{ question.leftSide }} ,scale:
                {{ question.leftSideNumber }}
              </b-field>
              <b-field class="answer-value">
                <strong>To :</strong>
                {{ question.rightSide }} ,scale:
                {{ question.rightSideNumber }}
              </b-field>
            </div>

            <!-- star rating -->
            <div
              v-else-if="
                question.questionType == 'star rating' && question.name != null
              "
            >
              <b-field class="answer-value">
                <strong>Max Star :</strong>
                {{ question.rightSideNumber }}
              </b-field>
              <b-field class="answer-value">
                <b-rate
                  v-model="question.leftSideNumber"
                  :size="'is-large'"
                  :spaced="true"
                  :max="question.rightSideNumber"
                  :show-score="true"
                ></b-rate>
              </b-field>
            </div>

            <!-- matrix -->
            <div
              v-if="question.name != null && question.questionType == 'matrix'"
            >
              <!-- row -->
              <div class="answer-value">
                <strong>Row</strong>
              </div>
              <div class="answer-value">
                <span v-for="(data, index) in question.newMatrix" :key="index">
                  <div v-if="data.row > 1">
                    <b-field>
                      {{ data.value }}
                    </b-field>
                  </div>
                </span>
              </div>

              <!-- column -->
              <div class="answer-value">
                <strong>Column</strong>
              </div>
              <div class="answer-value">
                <span v-for="(data, index) in question.newMatrix" :key="index">
                  <div v-if="data.column > 1">
                    <b-field>
                      {{ data.value }}
                    </b-field>
                  </div>
                </span>
              </div>
            </div>

            <!-- checkbox and radio button -->
            <div
              v-else-if="
                (question.questionType == 'radio button' &&
                  question.name != null) ||
                  (question.questionType == 'checkbox' && question.name != null)
              "
            >
              <template v-if="question.questionType == 'checkbox'">
                <b-field
                  class="answer-value"
                  style="font-weight:bolder"
                  v-if="question.leftSideNumber != null"
                >
                  Minimum {{ question.leftSideNumber }}
                </b-field>
                <b-field class="answer-value" style="font-weight:bolder" v-else>
                  Minimum : No
                </b-field>
                <b-field
                  class="answer-value"
                  style="font-weight:bolder"
                  v-if="question.rightSideNumber != null"
                >
                  Maximum : {{ question.rightSideNumber }}
                </b-field>
                <b-field class="answer-value" style="font-weight:bolder" v-else>
                  Maximum : No
                </b-field>
              </template>
              <span v-for="(data, index) in answer" :key="index">
                <div class="answer-value">
                  <strong>Answer {{ index + 1 }}</strong>
                </div>
                <b-field class="answer-value">
                  Answer Id : {{ data.id }}
                </b-field>
                <div class="answer-value">Type : {{ data.type }}</div>
                <div class="answer-value">
                  Is Valid ? :

                  <span v-if="data.isValid == 1">
                    Yes, {{ data.point }} point
                  </span>
                  <span v-else>No</span>
                </div>
                <!-- text -->
                <div class="answer-value" v-if="data.type == 'text'">
                  Description : {{ data.description }}
                </div>
                <div class="answer-value">
                  <span class="mr-2">Tags :</span>
                  <span v-for="tag in data.newTags" :key="tag.id">
                    <span class="tag-name">
                      {{ tag.name }}
                    </span>
                  </span>
                </div>

                <!-- image -->
                <div class="answer-value" v-if="data.type == 'image'">
                  <img
                    style="max-height: 300px; max-width: 300px;"
                    :src="getUrlFile(data.imagePath)"
                    alt="Answer Image"
                  />
                </div>
              </span>
            </div>
            <div
              class="answer-value"
              v-else-if="
                question.hasAnswer == 0 && question.questionType == 'essay'
              "
            >
              No Answer
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'form-bank-soal',
  components: { videoPlayer },
  props: {
    isOpen: Boolean,
    questionId: String,
  },
  data() {
    return {
      isLoading: false,
      open: this.isOpen,
      id: this.$props.questionId,
      isFullheight: true,
      isOverlay: true,
      isRight: true,
      searchTags: [],
      isFetching: false,
      question: null,
      videos: null,
      images: null,
      answer: [],
      countImg: 0,
    }
  },
  watch: {
    isOpen() {
      this.open = this.isOpen
    },
  },
  async created() {
    this.isLoading = true
    const response = await this.fetchQuestionStorage(this.id)
    this.question = response.data.question
    this.answer = response.data.answer
    this.videos = response.data.questionVideo
    this.images = response.data.questionImage
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      fetchQuestionStorage: 'questionStorage/fetchQuestionStorage',
    }),

    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },

    closeSideBar(id) {
      if (id && id != 'escape') {
        this.$emit('close', false, id)
        this.open = false
      } else {
        this.$emit('close', false)
        this.open = false
      }
    },

    // For play video
    playVideo(indexFile) {
      this.$refs['video_' + indexFile][0].player.play()
    },

    // For set options
    setOptions(src) {
      return {
        // videojs options
        muted: false,
        controls: false,
        width: 320,
        height: 240,
        language: 'en',
        sources: [
          {
            type: 'video/mp4',
            src: src,
          },
        ],
      }
    },

    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>

<style lang="scss">
.b-sidebar .sidebar-content.is-fixed.is-right {
  width: 60% !important;
  padding-right: 10.5px;
  border: 1px solid whitesmoke;
}
.form {
  padding-left: 10px !important;

  &-title {
    color: #000000 !important;
    font-size: 24px !important;
    font-weight: 900 !important;
  }
}

.question-description {
  font-weight: bolder;
}
.question-value {
  margin-top: 0.5rem;
}
.answer-value {
  margin-bottom: 10px !important;
}

.verticalLine {
  border-right: medium solid #dbdbdb;
}

.bottomLine {
  border-bottom: medium solid #dbdbdb;
}
.tag-name {
  background-color: #00b79f;
  border-radius: 1rem;
  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
  margin-right: 0.7rem;
  color: #ffffff;
}
.edit-pen {
  vertical-align: middle;
  color: #00a3a3 !important;
}
.edit-pen:hover {
  cursor: pointer;
}
.question-text ul {
  list-style: disc inside;
}
ol {
  list-style: inside decimal;
}
.form-title {
  font-family: 'Nunito Sans', Arial;
  font-weight: 900;
}

.button-close-top {
  font-family: 'Nunito Sans', Arial;
  font-weight: 600;
}
</style>
